module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rentaflix","short_name":"Rentaflix","start_url":"/","background_color":"#ffffff","theme_color":"#3f51b5","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f9a308075fd7209826d15472ed1ca8d6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","de"],"defaultLanguage":"en","siteUrl":"https://rentaflix.com","trailingSlash":"always","redirect":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"defaultNS":"translation","ns":["translation","consent","seo","footer","header","privacy","terms","contact","slides","documentation","article","tutorial_template","template_fields","template_field_descriptions","pricing"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
